import React from 'react'
import { hooks } from '@front/volcanion'

import GeoUtils from '@front/volcanion/utils/geo'

const withContainer = Component => ({ event_id, ...props }) => {
  const [incident] = hooks.useModel('event', [event_id], {
    populate: [
      'driver.info',
      'vehicle.info.model.brand',
      'transport.license',
      'vehicle.driver.info',
      'order.do.info',
      'order.do.customerinfo',
      'order.do.commercial_formula',
      'order.service.contract.formula',
      'order.service.contract.company',
      'order.source.poi',
      'order.loads',
      'produced_by.commercial_formula'
    ],
    single: true
  })

  const load_type = _.head(incident?.order?.loads)?.type
  const order_status = hooks.useOrderStatus(incident?.order, { translated: true })
  const transport_status = hooks.useTransportStatus(incident?.transport, { translated: true, load_type })
  const order_status_color = hooks.useOrderStatusColor(incident?.order)
  const transport_status_color = hooks.useTransportStatusColor(incident?.transport)
  const vehicle_status = hooks.useVehicleStatus(incident?.vehicle, { translated: true })

  const driver_id = incident?.vehicle?.driver?.user_id
  const driver_client_id = incident?.vehicle?.driver?.info?.user_client_id
  const license_group_id = incident?.transport?.license?.group
  const license_id = incident?.transport?.license?.license_id
  const license_client_id = incident?.transport?.license?.license_client_id
  const vehicle_id = incident?.vehicle?.vehicle_id
  const vehicle_group_id = incident?.vehicle?.group
  const vehicle_info = _.join(_.compact([
    incident?.vehicle?.info?.model?.name,
    incident?.vehicle?.info?.model?.brand?.name
  ]), '')
  const comment_reason = incident?.data?.find((d) => d.key === 'comment')?.value

  const user_id = incident?.producer_usertype === 'booker' ? incident?.produced_by?.user_id : incident?.order?.do?.user_id
  const commercial_formula = incident?.producer_usertype === 'booker' ? incident?.produced_by?.commercial_formula : incident?.order?.service?.contract?.formula || incident?.order?.do?.commercial_formula
  const formula_id = commercial_formula?.commercial_formula_id
  const formula_name = commercial_formula?.label_for_call_center
  const company_id = incident?.order?.service?.contract?.company?.company_id
  const company_name = incident?.order?.service?.contract?.company?.name
  const source = GeoUtils.format(incident?.order?.source)
  const schedule_type = incident?.order?.schedule_type
  const service_id = incident?.order?.service?.companyservice_id
  const requestedAt = incident?.order?.requestedAt

  const [{ name: user_name }] = hooks.useUserInfo(user_id, service_id)

  const mergedProps = {
    schedule_type,
    source,
    company_id,
    company_name,
    formula_id,
    formula_name,
    user_id,
    user_name,
    driver_id,
    driver_client_id,
    license_id,
    license_group_id,
    license_client_id,
    vehicle_id,
    vehicle_group_id,
    vehicle_info,
    incident,
    order_status_color,
    transport_status_color,
    order_status,
    transport_status,
    vehicle_status,
    comment_reason,
    requestedAt,
  }
  return (
    <Component {...mergedProps} />
  )
}
export default withContainer
